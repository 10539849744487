/* eslint-disable react/jsx-pascal-case */
import { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import {
  MRT_ColumnDef,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MaterialReactTable,
} from "material-react-table";
import {
  Business,
  Discount,
  Groups,
  Payments,
  Subscriptions,
  Visibility,
} from "@mui/icons-material";
import {
  ColumnFiltersState,
  Getter,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";

import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage";
import filterModel from "../../hooks/filterModel";
import StatusButton from "../../component/statusButton";
import CustomDateFilter from "../../component/customDateFilter";
import CustomFilterPanel from "../../component/customFilterPanel";
import useUpdateEntity from "../../hooks/useUpdateEntity";

// System Merchants List View Component
function Merchants() {
  // const { systemList, assignRole, revokeRole, getUserByPhone } =
  //     useAccessControl()
  // USESTATES & USEREFS
  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>() as any;
  const [columnFilterFns, setColumnFilterFns] = useState({
    created_at: "is",
    email: "contains",
    name: "contains",
    merchant_name: "contains",
    address: "contains",
    phone_number: "contains",
    status: "is",
  }) as any;
  const [globalFilter, setGlobalFilter] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>() as any;
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });
  const [merchantID, setMerchantID] = useState<string>("");
  // QUERY & MUTATION
  const sort = useMemo(
    () =>
      sorting?.map(
        (item: {
          id?: string;
          field?: string;
          sort?: string;
          desc?: string;
        }) => ({
          field: item?.id || item?.field,
          sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
        })
      ),
    [sorting]
  );
  const {
    data: merchants,
    isLoading,
    refetch,
  } = useFetchEntitiesPerPage(
    {
      endPoint: "merchants",
      page: pagination.pageIndex,
      perPage: pagination.pageSize,
      filterModel: filterModel(columnFilterFns, columnFilters) as any,
      sortModel: sort,
      searchText: globalFilter,
    },
    {
      // enabled: systemList,
    }
  ) as any;
  const { mutate, isPending, isSuccess } = useUpdateEntity(
    `merchants/${merchantID}`
  );

  useEffect(() => {
    if (isSuccess) refetch();
  }, [isSuccess, refetch]);

  // EVENT HANDLERS & HELPERS
  const handleSwitch = useCallback(
    (merchant: { status: string; id: string }) => {
      setMerchantID(merchant?.id);
      mutate({
        status: merchant.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const getAvatar = useCallback(
    ({ row }: { row: { original: { logo: string } } }) => (
      <Avatar
        sx={{
          width: 50,
          height: 50,
        }}
        src={row?.original?.logo}
      />
    ),
    []
  );
  const statusButton = useCallback(
    ({ row }: { row: { original: any } }) => (
      <StatusButton
        loading={isPending}
        status={row.original?.status}
        onChange={() => handleSwitch(row?.original)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPending]
  );
  const actions = useCallback(
    ({ row }: { row: { original: any } }) => (
      <Box sx={{ flex: 1 }}>
        <Tooltip title="View Details">
          <Link to={`${row.original.id}/view`} state={row.original}>
            <IconButton sx={{ color: "primary.main" }}>
              <Visibility />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="Products">
          <Link to={`${row.original.id}/products`} state={row.original}>
            <IconButton sx={{ color: "primary.main" }}>
              <Business />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="User Subscriptions">
          <Link
            to={`${row.original.id}/user-subscriptions`}
            state={row.original}
          >
            <IconButton sx={{ color: "primary.main" }}>
              <Subscriptions />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="One-Time Payments">
          <Link
            to={`${row.original.id}/one-time-payments`}
            state={row.original}
          >
            <IconButton sx={{ color: "primary.main" }}>
              <Payments />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="Customers">
          <Link to={`${row.original.id}/customers`} state={row.original}>
            <IconButton sx={{ color: "primary.main" }}>
              <Groups />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="Discount Codes">
          <Link to={`${row.original.id}/discountCodes`} state={row.original}>
            <IconButton sx={{ color: "primary.main" }}>
              <Discount />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
    ),
    []
  );

  const datePicker = (props: any) => <CustomDateFilter {...props} />;

  // DATA STRUCTURE
  const columns = useMemo<MRT_ColumnDef<object>[]>(
    () => [
      {
        size: 150,
        accessorKey: "photo",
        header: "Logo",
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        Cell: getAvatar as any,
      },
      {
        accessorKey: "name",
        header: "Trade Name",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 220,
        accessorKey: "merchant_name",
        header: "Merchant Name",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        accessorKey: "phone_number",
        header: "Phone",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 220,
        accessorKey: "address",
        header: "Address",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 220,
        accessorKey: "email",
        header: "Email",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 230,
        align: "center",
        accessorKey: "status",
        header: "Status",
        filterVariant: "select" as any,
        filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
        Cell: statusButton as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "created_at",
        header: "Registered Time",
        filterVariant: "date" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: datePicker,
      },
      // {
      //   size: 280,
      //   accessorKey: "actions",
      //   header: "Actions",
      //   enableSorting: false,
      //   enableColumnFilter: false,
      //   enableGlobalFilter: false,
      //   Cell: actions,
      // },
    ],
    [getAvatar, statusButton]
  );

  return (
    <Box sx={{ height: "100%" }}>
      <Box sx={{ flex: 1, height: "100%" }}>
        <MaterialReactTable
          data={merchants ? merchants.data : []}
          columns={columns}
          initialState={{
            columnPinning: {
              left: ["mrt-row-select"],
              right: ["actions"],
            },
          }}
          enableColumnResizing
          enableColumnFilterModes
          enableStickyHeader
          enableColumnOrdering
          enableRowSelection
          enablePinning
          manualFiltering
          manualPagination
          manualSorting
          filterFns={{
            after: (row: any, filterValue: any) =>
              row.customField === filterValue,
          }}
          muiFilterTextFieldProps={({ column }: any) => ({
            helperText: `Filter Mode: ${columnFilterFns[column?.id]}`,
          })}
          onColumnFiltersChange={setColumnFilters}
          onColumnFilterFnsChange={setColumnFilterFns}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderToolbarInternalActions={({ table }: any) => (
            <>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </>
          )}
          muiTableContainerProps={{
            sx: { maxHeight: `calc(100vh - 225px)` },
          }}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
            },
          }}
          muiTableBodyCellProps={({ table, column }: any) => {
            return {
              sx: {
                "&.MuiTableCell-root": {
                  boxShadow:
                    table.getState().columnPinning?.right?.[0] === column?.id
                      ? "-7px 0px 10px -1.7px lightgray"
                      : table
                            .getState()
                            .columnPinning?.left?.some(
                              (el: string) => el === column.id
                            )
                        ? "7px 0px 10px -1.7px lightgray"
                        : "none",
                },
              },
            };
          }}
          rowCount={merchants?.meta_data?.total ?? 0}
          state={{
            columnFilters: columnFilters || [],
            columnFilterFns,
            globalFilter: globalFilter || "",
            isLoading,
            pagination,
            sorting: sorting || [],
            showSkeletons: isLoading,
          }}
        />
      </Box>
    </Box>
  );
}
export default Merchants;
